import { API } from "aws-amplify";
import React, { Component } from "react";
import {Animated} from "react-animated-css";
import ReactDOM from "react-dom";
import ScrollAnimation from 'react-animate-on-scroll';
import { ControlLabel, FormControl, FormGroup, HelpBlock } from "react-bootstrap";

import config from "../config";
import "./Condico.css";
import CreateCondicoUnAuthForm from '../components/CreateCondicoUnAuthForm';
import introImage from '../assets/condicoSite/ShadesTop.png';
import footerImage from '../assets/condicoSite/footer_background.jpg';
import utilityFunctions from "../utilityFunctions";
import VideoContainer from "../components/VideoContainer";

var Loader = require('react-loader');

class Condico extends Component {

  constructor() {
    super();
    this.sendEmail = this.sendEmail.bind(this);
    this.handleCreateCondicoUnAuthForm = this.handleCreateCondicoUnAuthForm.bind(this);
    this.oldPath = '';
    this.state = {
      email: '',
      firstname: '',
      lastname: '',
      message: '',
      hideLoader: true
    }
  }

  scrollToHash() {
    utilityFunctions.log(`location ${JSON.stringify(this.props.location)}`)
    let path = this.props.location.pathname.replace('/', '');
    //let hash = this.props.location.hash.replace('#', '');
    utilityFunctions.log(`path ${path}`);
    if (path) {
      utilityFunctions.log(`path ${path}`);
        let node = ReactDOM.findDOMNode(this.refs[path]);
        utilityFunctions.log(this.refs[path])
        if (node) {
            node.scrollIntoView({block: 'end', behavior: 'smooth'});
        }
    }
    this.props.unSelectNavItem();
  }

  componentDidMount() {
    let path = this.props.location.pathname.replace('/', '');
    utilityFunctions.log(`Condico componentDidMount path is ${path}`);
    this.scrollToHash();
  }

  componentDidUpdate() {
    let path = this.props.location.pathname.replace('/', '');
    utilityFunctions.log(`Condico componentDidUpdate path is ${path}`);
    if (config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled) {
      console.log(`CHECKING PATH FOR MAIN PAGE REDIRECT IN CONDICO.js ${this.props.location.pathname}!`);
      if (this.props.location.pathname === '/' || this.props.location.pathname === '/top' || this.props.location.pathname === '/oneA' || this.props.location.pathname === '/two' || this.props.location.pathname === '/three' || this.props.location.pathname === '/about') {
        window.location = config.getEnvironmentConfig(config.Environment).marketingPage.leadPagesRedirectionUrl;
        console.log('CHECKED PATH!!!');
      } else {
        if (!!this.props.selectedNavItem) {
          if (path !== 'three') {
            this.scrollToHash();
            this.oldPath = path;
          } else {
            if (this.oldPath !== 'three') {
              this.scrollToHash();
              this.oldPath = 'three';
            }
          }
        }
      }
    } else {
      if (!!this.props.selectedNavItem) {
        if (path !== 'three') {
          this.scrollToHash();
          this.oldPath = path;
        } else {
          if (this.oldPath !== 'three') {
            this.scrollToHash();
            this.oldPath = 'three';
          }
        }
      }
    }
  }

  stepSelected(path) {
    utilityFunctions.log(`Condico stepSelected path is ${path}`);
    if (path !== 'three') {
      let node = ReactDOM.findDOMNode(this.refs[path]);
      utilityFunctions.log(this.refs[path])
      if (node) {
            node.scrollIntoView({block: 'end', behavior: 'smooth'});
      }
      this.oldPath = path;
    } else {
      if (this.oldPath !== 'three') {
        let node = ReactDOM.findDOMNode(this.refs[path]);
        utilityFunctions.log(this.refs[path])
        if (node) {
            node.scrollIntoView({block: 'end', behavior: 'smooth'});
        }
        this.oldPath = 'three';
      }
    }
  }

  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}} id="wrapper">
        <div ref="top" ></div>
        {this.renderIntroSection()}
        {this.renderStepOneSection()}
        {this.renderStepTwoSection()}
        {this.renderStepThreeSection()}
        {this.renderFAQSection()}
        {this.renderContactUsSection()}
        {this.renderFooter()}
      </div>
    );
  }

  handleCreateCondicoUnAuthForm(unAuthCondicoFormData) {
    utilityFunctions.log(`handleCreateCondicoUnAuthForm called ${JSON.stringify(unAuthCondicoFormData)}`);
    // temp force creationDataUnauth setting of data without frontend form
    this.props.setCreationDataUnauthenticated(unAuthCondicoFormData);
    // end temp code
  }

  renderIntroSection() {
    var widthStyle = {};
    var mobile = false;
    if (document.documentElement.clientWidth <= 500) {
      widthStyle = {width: '5%'};
      mobile = true;
    }
    else {
      widthStyle = {width: '10%'};
    }
    return (
      <section margin="50px" id="intro" className="wrapper style1 fullscreen fade-up" width="100%" style={{backgroundImage: 'url(' + introImage + ')'}}>
        <div style={{textAlign:'center'}} width="50%" >
          <br /><br />
          {!mobile && <span>< br /><br /></span>}
          <table border="0" width="50%">
            <tbody>
              <tr>
                <td>
                  <h1 style={{fontFamily: "Roboto, helvetica", color: config.ThemeColor, fontWeight: '400'}}>Have a dispute?</h1>
                  {
                    !mobile ? <h1 style={{fontFamily: "Roboto, helvetica", color: config.ThemeColor, fontWeight: '400', marginTop: "-25px"}}>You'll be happy with this settlement.</h1> :
                    <span>
                      <h1 style={{fontFamily: "Roboto, helvetica", color: config.ThemeColor, fontWeight: '400', marginTop: "-25px"}}>You'll be happy</h1>
                      <h1 style={{fontFamily: "Roboto, helvetica", color: config.ThemeColor, fontWeight: '400', marginTop: "-25px"}}>with this settlement.</h1>
                    </span>
                  }
                  <h2 style={{fontFamily: "Roboto, helvetica", color: 'black', fontWeight: 400}}>Automated dispute resolution where you control the outcome.</h2>
                  <div style={{marginLeft: "25%", width:"50%"}}>
                    <CreateCondicoUnAuthForm handleFormSubmit={(data) => this.handleCreateCondicoUnAuthForm(data)} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>{!mobile && <span>< br /></span>}
                  <div align="trailing" valign="top" >
                    <a href="https://play.google.com/store/apps/details?id=com.condico&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                      <img src={ "https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" } height="60px" margin="0px" padding="0px" alt="Get it on Google Play" />
                    </a>
                    <a href="https://itunes.apple.com/us/app/mycondico/id1378969420?ls=1&mt=8">
                      <img src={ "https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2018-12-01&kind=iossoftware&bubble=ios_apps" } height="40px" width="135px" margin="0px" padding="0px" alt="app download" />
                    </a>
                  </div><br />
                  {!mobile && <span>< br /></span>}
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <div 
                        style={{width: '25%'}}
                      >
                      </div>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '25%'}}>
                        <ScrollAnimation animateIn="zoomInUp" duration="2">
                          <VideoContainer video="pSE803gF_qY" autoplay="0" rel="0" modest="1" playtoon={true} demo={false}/>
                        </ScrollAnimation>
                      </div>
                      <div 
                        style={{width: '25%'}}
                      >
                      </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{display:'flex', justifyContent: 'center'}}>
          <div style={{textAlign:'left'}}>
            <ScrollAnimation animateIn="zoomInUp" duration={!mobile ? "3" : "0"}>
              <font style={{fontWeight:"600"}} face="Roboto, helvetica" color="#556B2F"><button style={{backgroundColor: 'transparent', border: 'none', fontSize: !mobile ? "18px" : "16px"}} onClick={() => {this.stepSelected("oneA")}}><font color="black" face="Roboto, helvetica" >Step 1 - </font>Briefly describe your case</button></font><br />
              <font style={{fontWeight:"600"}} face="Roboto, helvetica" color="#556B2F"><button style={{backgroundColor: 'transparent', border: 'none', fontSize : !mobile ? "18px" : "16px"}} onClick={() => {this.stepSelected("oneB")}}><font color="black" face="Roboto, helvetica" >Step 2 - </font>Condico contacts the other party</button></font><br />
              <font style={{fontWeight:"600"}} face="Roboto, helvetica" color="#556B2F"><button style={{backgroundColor: 'transparent', border: 'none', fontSize : !mobile ? "18px" : "16px"}} onClick={() => {this.stepSelected("oneC")}}><font color="black" face="Roboto, helvetica" >Step 3 - </font>Resolve your dispute</button></font>
            </ScrollAnimation>
          </div>
        </div>
        <br />< br /><br />< br /><br />< br /><br />
        {!mobile && <span>< br />< br /><br />< br /><br />< br />< br /><br /></span>}
      </section>
    );
  }

  renderStepOneSection() {
    var mobile = false;
    if (document.documentElement.clientWidth <= 500) {
      mobile = true;
    }
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white'}}>
        <div style={{width: mobile ? '90%' : '70%'}}>
          <br /><br />
          <section className="CondicoStepsSubSection" ref="oneA">
            <ScrollAnimation animateIn="lightSpeedIn">
                <img style={{margin: '20px'}} src={ require("../assets/condicoSite/pic01.png") } alt="" width="150px" />
            </ScrollAnimation>
            <ScrollAnimation animateIn="lightSpeedIn">
              <div>
                  <font size="+2"><font face="Roboto, helvetica" color="#99CC00">Step 1 - What's the skinny?</font></font><br />
                  <div style={{hyphens: "auto", textAlign:'justify'}}>
                    <font face="Roboto, helvetica" > Briefly describe your negotiation. Just identify the subject, such as an invoice 
      number or a court file number, and an email address for the other party. On a confidential basis, tell Condico your bottom line position, either how much you 
      would accept from the other party, or how much you would be willing to pay, to resolve the dispute once and for all.</font>
                  </div>
              </div>
            </ScrollAnimation>
            <div></div>
          </section>
          <br /><br />
        </div>
      </div>
    );
  }

  renderStepTwoSection() {
    var mobile = false;
    if (document.documentElement.clientWidth <= 500) {
      mobile = true;
    }
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#C8C8C8'}}>
        <div style={{width: mobile ? '90%' : '70%'}}>
          <br /><br />
          <section className="CondicoStepsSubSection">
            <ScrollAnimation animateIn="lightSpeedIn">
                <img style={{margin: '20px'}} src={ require("../assets/condicoSite/pic02.png") } alt="" width="150px" />
            </ScrollAnimation>
            <div>
            <ScrollAnimation animateIn="lightSpeedIn">
                <font size="+2"><font face="Roboto, helvetica" color="#99CC00">Step 2 - Relax</font></font><br />
                <div style={{hyphens: "auto", textAlign:'justify'}}>
                  <font face="Roboto, helvetica" >Our app will automatically contact the other party and invite them to participate in bringing your dispute to an end. The other party tells Condico, on a confidential basis, their bottom line position.</font>
                </div>
              </ScrollAnimation>
            </div>
            <div></div>
          </section>
          <br ref="oneB"/><br />
        </div>
      </div>
    );
  }

  renderStepThreeSection() {
    var mobile = false;
    if (document.documentElement.clientWidth <= 500) {
      mobile = true;
    }
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#989898'}}>
        <div style={{width: mobile ? '90%' : '70%'}}>
          <br /><br />
          <section className="CondicoStepsSubSection">
            <ScrollAnimation animateIn="lightSpeedIn">
                <img style={{margin: '20px'}} src={ require("../assets/condicoSite/pic03.png") } alt="" width="150px" />
            </ScrollAnimation>
            <ScrollAnimation animateIn="lightSpeedIn">
              <div>
                  <font size="+2"><font face="Roboto, helvetica" color="#99CC00">Step 3 - Condico!</font></font><br />
                  <div style={{hyphens: "auto", textAlign:'justify'}}>
                    <font face="Roboto, helvetica" >If the offered amount is the same or more than the amount willing to be accepted, you're done. Condico will provide the parties with the settlement figure and certificate, and the parties can arrange for payment. Otherwise, the condico can continue, or not. It is up to you. If there is no settlement, however, neither party ever has any more additional information about the other side's position than before the condico got started. You can't lose!</font>
                  </div>
              </div>
            </ScrollAnimation>
            <div></div>
          </section>
          <br ref="oneC"/><br />
        </div>
      </div>
    );
  }

  renderFAQSection() {
    var mobile = false;
    if (document.documentElement.clientWidth <= 500) {
      mobile = true;
    }
    return (
      <section style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white'}}>
				<div style={{width: mobile ? '90%' : '70%'}}><br /><br />
          <p align="center"><font size="+2"><font face="Roboto, helvetica" color="#333333">Frequently Asked Questions</font></font></p>
          <div className="features">
            <section ref="two">
              <ScrollAnimation animateIn="fadeIn">
                <span className="icon major"><img src={ require("../assets/condicoSite/envelope_icon.jpg") } alt="" /></span>
                <h3 style={{fontFamily: "Roboto, helvetica"}}>If I am invited to participate in a condico, do I have to?</h3>
                <p style={{hyphens: "auto"}} align="justify"><font size="100%"><font face="Roboto, helvetica" color="#333333">No. Participating in a condico transaction is entirely voluntary.</font></font></p>
              </ScrollAnimation>
            </section>
            <section>
              <ScrollAnimation animateIn="fadeIn">
                <span className="icon major"><img src={ require("../assets/condicoSite/gear_icon.jpg") } alt="" /></span>
                <h3 style={{fontFamily: "Roboto, helvetica"}}>How does Condico decide on a settlement figure?</h3>
                <p style={{hyphens: "auto"}} align="justify"><font size="100%"><font face="Roboto, helvetica" color="#333333">We compare the amount that one party is willing to pay, called the "ceiling," to the amount the other party is willing to accept, called the "floor."  If the ceiling is greater than the floor, we settle the negotiation at a random number between the two. If the ceiling is less than the floor, there is no settlement to be had.</font></font></p>
              </ScrollAnimation>
            </section>                
            <section>
              <ScrollAnimation animateIn="fadeIn">
                <span className="icon major"><img src={ require("../assets/condicoSite/number_icon.jpg") } alt="" /></span>
                <h3 style={{fontFamily: "Roboto, helvetica"}}>Why does Condico resolve negotiations with a random number?</h3>
                <p style={{hyphens: "auto"}} align="justify"><font size="100%"><font face="Roboto, helvetica" color="#333333">That way, neither party can ever be sure what the other party's bottom line position was. If condico simply decided settlements in a predictable way, parties would be able to reverse-engineer the settlement and learn what the other side's bottom line was. We feel that would hurt the integrity of the whole process.</font></font></p>
              </ScrollAnimation>
            </section>        
            <section>
              <ScrollAnimation animateIn="fadeIn">
                <span className="icon major"><img src={ require("../assets/condicoSite/lock_icon.jpg") } alt="" /></span>
                <h3 style={{fontFamily: "Roboto, helvetica"}}>What do I get out of a settlement with Condico?</h3>
                <p style={{hyphens: "auto"}} align="justify"><font size="100%"><font face="Roboto, helvetica" color="#333333">When Condico resolves a dispute and tells each party to the negotiation the settlement figure, the parties are bound by the settlement. If the payor doesn’t pay, the payee can enforce the settlement just as they would any other contract. After the settlement payment is made, the payee no longer has the right to make any claim in connection with the issue that was the subject of the dispute.</font></font></p>
              </ScrollAnimation>
            </section>
            <section>
              <ScrollAnimation animateIn="fadeIn">
                <span className="icon major"><img src={ require("../assets/condicoSite/dollarSign.jpg") } alt="" /></span>
                <h3 style={{fontFamily: "Roboto, helvetica"}}>How much does it cost to use Condico?</h3><br />
                <div style= {{hyphens: "auto", paddingLeft: "10px"}} align="justify"><font face="Roboto, helvetica" style={{fontWeight:"600"}} size="100%"><font color="#333333">If you have a dispute concerning $1,000 or less, Condico is happy to help you resolve the dispute for free.</font></font></div><br />
                <div style= {{hyphens: "auto", paddingLeft: "10px"}} align="justify"><font face="Roboto, helvetica" style={{fontWeight:"600"}} size="100%"><font color="#333333">For disputes concerning amounts greater than $1,000, the initiator of a successful Condico is charged according to the following fee schedule, subject to the terms of any accepted promo code:</font></font></div><br />
                <font face="Roboto, helvetica" size="3.5"><font face="Roboto, helvetica" style={{marginLeft:"-20px"}} color="#99CC00">I.</font> Settlements of $1,001 - $5,000:</font><br />
                <p style={{marginTop: '-15px'}} align="justify"><font face="Roboto, helvetica" size="100%"><font color="#333333">$50</font></font></p>
                <font face="Roboto, helvetica" size="3.5"><font face="Roboto, helvetica" style={{marginLeft:"-20px"}}color="#99CC00">II.</font> Settlements of $5,001 - $20,000:</font><br />
                <p style={{marginTop: '-15px'}} align="justify"><font face="Roboto, helvetica" size="100%"><font color="#333333">$150</font></font></p>
                <font face="Roboto, helvetica" size="3.5"><font face="Roboto, helvetica" style={{marginLeft:"-20px"}} color="#99CC00">III.</font> Settlements of $20,001 - $25,000:</font><br />
                <p style={{marginTop: '-15px'}} align="justify"><font face="Roboto, helvetica" size="100%"><font color="#333333">$500</font></font></p>
                <font face="Roboto, helvetica" size="3.5"><font face="Roboto, helvetica" style={{marginLeft:"-20px"}} color="#99CC00">IV.</font> Settlements of $25,001 - $100,000:</font><br />
                <p style={{marginTop: '-15px'}} align="justify"><font face="Roboto, helvetica" size="100%"><font color="#333333">$750</font></font></p>
                <font face="Roboto, helvetica" size="3.5"><font face="Roboto, helvetica" style={{marginLeft:"-20px"}} color="#99CC00">V.</font> Settlements more than $100,000:</font><br />
                <p style={{marginTop: '-15px'}} align="justify"><font face="Roboto, helvetica" size="100%"><font color="#333333">$1000</font></font></p>
              </ScrollAnimation>
            </section>
            <section>
              <ScrollAnimation animateIn="fadeIn">
                <span className="icon major"><img src={ require("../assets/condicoSite/playIcon.svg") } style={{marginLeft: 10}} height="30px" alt="" /></span>
                <h3 style={{fontFamily: "Roboto, helvetica"}}>See Demo</h3>
                <VideoContainer video="FtrDtp27Gt0" autoplay="0" rel="0" modest="1" playtoon={false} demo={true} />	
              </ScrollAnimation>
            </section>					
          </div>         
				</div>
			</section>
    );
  }

  renderContactUsSection() {
    var widthStyle = {};
    if (document.documentElement.clientWidth <= 500) {
      widthStyle = {width: '90%', margin: '1px'};
    } else {
      widthStyle = {width: '50%', margin: '50px'};
    }
    return (
      <div ref="three" style={{backgroundImage: 'url(' + footerImage + ')', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<div style={widthStyle} >
          <div style={{alignItems: 'flex-start'}} >
            <h2><font color="#FFFFFF"><br /><br />
              Get in touch</font></h2>
            <p><font color="#FFFFFF">We welcome all questions and comments.</font></p>
          </div>
          <form className="CondicoForm" onSubmit={this.sendEmail} >
            <div className="CondicoContactSubSection">
              <FormGroup
                  controlId="firstname"
                  validationState={this.getFirstnameValidationState()}
                  style={{flex: 1, marginRight: '15px'}}
              >
                  <ControlLabel style={{color: 'white'}}>First Name:</ControlLabel>
                  <FormControl
                      autoComplete="nope"
                      style={{'webkitTextFillColor': 'white', 'webkit-box-shadow': '0 0 0px 1000px #000 inset', transition: 'background-color 5000s ease-in-out 0s', color: 'white', backgroundColor: 'transparent'}}
                      type="text"
                      onChange={this.handleFirstnameChange}
                      value={this.state.firstname}
                  />
                  <FormControl.Feedback />
                  { (this.getFirstnameValidationState() === "success" || this.getFirstnameValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Bad format: min 2, A-z, no special char</HelpBlock> }
              </FormGroup>
              <FormGroup
                controlId="lastname"
                validationState={this.getLastnameValidationState()}
                style={{flex: 1}}
              >
                <ControlLabel style={{color: 'white'}}>Last Name:</ControlLabel>
                <FormControl
                    autoComplete="nope"
                    style={{'webkitTextFillColor': 'white', 'webkit-box-shadow': '0 0 0px 1000px #000 inset', transition: 'background-color 5000s ease-in-out 0s', color: 'white', backgroundColor: 'transparent'}}
                    type="text"
                    onChange={this.handleLastnameChange}
                    value={this.state.lastname}
                />
                <FormControl.Feedback />
                { (this.getLastnameValidationState() === "success" || this.getLastnameValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Bad format: min 2, A-z, no special char</HelpBlock> }
              </FormGroup>
            </div>
            <div className="CondicoContactSubSection">
              <FormGroup
                  controlId="email"
                  validationState={this.getEmailValidationState()}
                  style={{flex: 1, marginRight: '15px'}}
              >
                  <ControlLabel style={{color: 'white'}}>Email:</ControlLabel>
                  <FormControl
                      style={{'webkitTextFillColor': 'white', 'webkit-box-shadow': '0 0 0px 1000px #000 inset', transition: 'background-color 5000s ease-in-out 0s', color: 'white', backgroundColor: 'transparent'}}
                      type="email"
                      onChange={this.handleEmailChange}
                      value={this.state.email}
                  />
                  <FormControl.Feedback />
                  { (this.getEmailValidationState() === "success" || this.getEmailValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Ex. name@domain.com</HelpBlock> }
              </FormGroup>
              <FormGroup
                  controlId="message"
                  validationState={this.getMessageValidationState()}
                  style={{flex: 1}}
              >
                <ControlLabel style={{color: 'white'}}>Message:</ControlLabel>
                <FormControl
                    style={{color: 'white', backgroundColor: 'transparent'}}
                    onChange={this.handleMessageChange}
                    value={this.state.message}
                    componentClass="textarea"
                />
                <FormControl.Feedback />
                { (this.getMessageValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock style={(this.getMessageValidationState() === "success" || this.getMessageValidationState() === null) ? {color: 'green'} : {color: 'red'}}>{`Char min ${config.MIN_MESSAGE_LENGTH}, max ${config.MAX_MESSAGE_LENGTH}. Char remaining: ${config.MAX_MESSAGE_LENGTH - this.state.message.length}`}</HelpBlock> }
              </FormGroup>
            </div>
            {
              !this.state.hideLoader && <Loader color='white' loaded={this.state.hideLoader} />
            }
            <button style={this.state.validForm ? {backgroundColor: config.ThemeColor, color: 'white'} : {backgroundColor: 'transparent', color: 'white'}} disabled={!this.state.validForm} className="button">Send Message</button>
          </form>
          <section style={{marginTop: '20px'}}>
            <ul className="contact">
              <li>
                <h3><font color="#FFFFFF">Address</font></h3>
                <span><font color="#FFFFFF">14 Fairleigh Crescent<br />
                  Toronto, ON<br />
                </font></span><font color="#FFFFFF">M6C 3R8</font>
              </li>
              <li>
                <h3><font color="#FFFFFF">Phone</font></h3>
                <span><font color="#FFFFFF">(647) 616-4338</font></span>
              </li>
            </ul>
            <section id="three"></section>
          </section>
        </div>
      </div>
    )
  }

  renderFooter() {
    return (
      <footer id="footer" className="wrapper style1-alt">
        <div className="inner">
          <ul className="menu" align="right">
            <li><font color="#FFFFFF"><br /><br />
            <a href="/terms" onClick={this.termsAndConditions}><font color="#FFFFFF">Terms & Conditions</font></a>
              &copy; Condico. All rights reserved.</font></li><li></li>
          </ul>
        </div>
      </footer>
    );
  }

  termsAndConditions = event => {
    utilityFunctions.log("showing terms");
    this.props.history.push(event.currentTarget.getAttribute("href"));
  }

  sendEmail = async  event => {
    event.preventDefault();
    this.setState({hideLoader: false});
    utilityFunctions.log("sending email...");
    try {
      let emailResponse = await this.sendPublicEmail({
        emailInfo: {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            message: this.state.message
        }
      });
      utilityFunctions.log(`email response is ${emailResponse}`);
      this.checkResult(emailResponse);
    } catch (e) {
        this.setState({hideLoader: true});
        utilityFunctions.log(`error catch at email sending with error ${e} `);
        if (!!e.response && !!e.response.data) {
            if(e.response.data.message === "Not authorized") {
            utilityFunctions.log("NOT AUTHORIZED");
            this.props.handleLogout();
            return;
            }
        }
        this.props.handleSetAppError();
    }
  }

  sendPublicEmail(data) {
    utilityFunctions.log(`sendPublicEmail called for POST with data ${JSON.stringify(data)}`);
    return API.post("publicContactUs", "/publicContact", {
      body: data.emailInfo
    });
  }

  checkResult(result) {
    utilityFunctions.log(`response is ${JSON.stringify(result)}`);
    if (result.contactUs.message === 'success') {
      this.setState({hideLoader: true, firstname: '', lastname: '', email: '', message: ''}, () => {
        setTimeout(function(){ alert("Thank you. Your message has been sent."); }, 100);
      });
    }
  }

  handleFirstnameChange = event => {
    this.setState({firstname: event.target.value}, () => this.validateForm());
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  getFirstnameValidationState() {
    const length = this.state.firstname.length;
    if (length > 1 && utilityFunctions.isAlpha(this.state.firstname)) return 'success';
    else if (length > 1 && !utilityFunctions.isAlpha(this.state.firstname)) return 'error';
    else if ( length > 0 ) return 'error';
    return null;
  }

  handleLastnameChange = event => {
    this.setState({lastname: event.target.value}, () => this.validateForm());
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  getLastnameValidationState() {
    const length = this.state.lastname.length;
    if (length > 1 && utilityFunctions.isAlpha(this.state.lastname)) return 'success';
    else if (length > 1 && !utilityFunctions.isAlpha(this.state.lastname)) return 'error';
    else if ( length > 0 ) return 'error';
    return null;
  }

  handleEmailChange = event => {
    this.setState({ email: event.target.value }, () => this.validateForm());
  }

  getEmailValidationState() {
    const length = this.state.email.length;
    if ((utilityFunctions.isEmail(this.state.email))) return 'success';
    else if ( length > 0 ) return 'error';
    return null;
  }

  handleMessageChange = event => {
    this.setState({message: event.target.value}, () => this.validateForm());
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  getMessageValidationState() {
    const length = this.state.message.length;
    if ( length <= config.MAX_MESSAGE_LENGTH && length >= config.MIN_MESSAGE_LENGTH ) return 'success';
    else if ( length > 0 ) return 'error';
    return null;
  }

  validateForm() {
    if (this.getFirstnameValidationState() === "success" &&
        this.getLastnameValidationState() === "success" && 
        this.getEmailValidationState() === "success" &&
        this.getMessageValidationState() === "success") {
            this.setState({validForm: true});
            return
    }
    utilityFunctions.log("validation failed");
    this.setState({validForm: false});
    return;
  }

}

const customStringify = function (v) {
  const cache = new Map();
  return JSON.stringify(v, function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.get(value)) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our map
      cache.set(value, true);
    }
    return value;
  });
};

export default Condico;